import React from 'react'
import Layout from '../components/Layout'
import SEO from "../components/SEO";

const Scholarship = () => (
    <Layout>
        <SEO
            title={"Poppy Energy Scholarship"}
            description={""}
        />
        <section className="w-full md:w-3/4 md:pr-4  px-4 md:px-6 py-6 container mx-auto markdown-styles">
            <h1 className="md:text-4xl font-semibold leading-10 text-3xl">Poppy Energy Scholarship</h1>


            <h2>About Poppy Energy</h2>

            <p>Poppy Energy is a woman-owned energy consulting company in the Sacramento, CA area specializing in <a
                href="/full-service-hers-testing/">energy
                audits and tests</a> of HVAC systems and insulation. Our mission is to offer the consumer an alternative
                to
                high priced and low quality inspections.</p>

            <h2>Scholarship Details and Entry</h2>

            <p>At Poppy Energy, we understand the complexities of running a business and see a lack of women owners,
                especially in the construction industry. To help raise awareness and to bring a spot light to women
                owned business, we will award a $1,000 scholarship to the winner of a creative and authentic response to
                an essay prompt.</p>

            <p className="font-bold">To be eligible for this scholarship, students must submit 1,500 word essay on the following question:</p>
            <ul>
                <li>How can the US achieve gender equality in business ownership and revenue?</li>
            </ul>


            <h3>Additional Prompt Information</h3>
            <p>According to National Association of Woman Business Owners (source), 39% of all privately held firms are
                women-owned and account for 4.2% of US overall revenue. This is a huge discrepancy between ownership and
                revenue when analyzing just the women owned companies.</p>

            <p>How can we achieve more women-owned business and increase women-owned business revenues?</p>

            <p> Feel free to write broadly on this topic to reflect personal experience as well as what local, state,
                federal and private institutions can do to help and achieve this equality.</p>

            <h3>Deadline</h3>

            <p>Deadline is October 15 of each calendar year (reoccurring scholarship).</p>

            <h3>Who is eligible?</h3>

            <p>Full-time students currently accepted to or enrolled at an American two- or four-year college or
                university</p>

            <h3>How will the winner get announced?</h3>

            <p>Poppy Energy will review all submissions and the winner of the scholarship will be selected by November 1
                and will be contacted via email. Please make sure to provide us with an accurate email address. The
                scholarship funds will be distributed by December 1.</p>

            <h2>How to Apply</h2>
            <p>Apply using the <a className="text-brand-500"
                                  href="https://forms.zohopublic.com/paul129/form/PoppyEnergyScholarshipApplication/formperma/dF-yTmYP3L4-xxL5B6-Y7hZZUgO6QhyygNCnAbShcwY?referrername=poppy_website">scholarship
                application</a>.</p>

        </section>
    </Layout>
)

export default Scholarship
